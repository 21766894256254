import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Share from './Share'
import './App.css';

class App extends Component {
    render() {
        console.log(process.env.PUBLIC_URL)
        return (
            <Router basename={'/s'}>
                <Route path={`/:id`} component={Share} />
            </Router>
        )
    }
}

export default App;
