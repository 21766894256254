import React, { Component } from 'react'
import firebase from './firebase'
import appstore from './appstore.svg';

class Share extends Component {

    state = {

    }

    componentDidMount = async () => {
        // this.setState({ isLoading: true })
        // TODO: Store Business ID locally to save the roundtrip
        // const business = JSON.parse(sessionStorage.getItem('business'))
        // let location = { businessID: business.businessID }

        console.log('componentDidMount')
        if (this.props.match.params.id) {
            try {
                firebase.storage()
                    .ref().child(`public/shares/${this.props.match.params.id}.png`)
                    .getDownloadURL()
                    .then( (downloadURL) => {
                        this.setState({ downloadURL })
                    })
                
            } catch (error) {
                this.setState({ error })
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                { this.state.downloadURL ? (
                    <div style={styles.recipe}>
                        <img src={this.state.downloadURL} alt="Recipe Instructions" style={{width: '80%', maxWidth: 1000}} />
                    </div>
                ) : (
                    <div style={{...styles.recipe, margin: 200}}>
                        Open this link on a mobile device to see your shared recipe.
                    </div>
                )}
                <footer style={{display: 'flex', justifyContent: 'center', marginTop: 40}}>
                    <div style={{display: 'flex', justifyContent: 'center', width: '75%', maxWidth: 900}}>
                        <div style={{flexGrow: 1, justifyContent: 'flex-start'}}>
                            <a href="https://itunes.apple.com/us/app/elemental-cocktails-for-cocktail/id1089182183?ls=1&mt=8"><img src={appstore} alt="Download on the App Store" /></a>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'flex-end', fontSize: 14}}>
                            © 2018 Elemental Cocktails
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}

const styles = {
    recipe: {
        display: 'flex',
        justifyContent: 'center', /* align horizontal */
        alignItems: 'center' /* align vertical */
    }
}


export default Share