import firebase from 'firebase/app';
import 'firebase/storage';

// import firebase from 'firebase'
// import firebase from 'firebase/app';
const config = {
    apiKey: "AIzaSyC-FoTs0oYvKGAIyUOMOd10yS1tQq3D-rE",
    authDomain: "elemental-cocktails.firebaseapp.com",
    databaseURL: "https://elemental-cocktails.firebaseio.com",
    projectId: "elemental-cocktails",
    storageBucket: "elemental-cocktails.appspot.com",
    messagingSenderId: "121542659238"
};
firebase.initializeApp(config);
export default firebase;